@use "../abstracts/reset";
@use "../abstracts/darkmode";

.introduction__section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 10rem; /* Adjust the margin based on your design */
  height: 95vh;
  gap: 8rem;

  &__text {
    width: 100%; // Ensure the text takes the full width
    text-align: center;
  }

  &__title {
    font-size: 5.5rem;
  }

  &__figure {
    width: 40rem;
    height: 40rem;
    border-radius: 50%;
    opacity: 0; // Initially set opacity to 0 for fade-in effect
    transform: translateX(-100%) rotate(-45deg); // Initially set translateX to move it off-screen to the left and rotate

    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out; // Add transition for smooth fade-in and translation

    &.fade-in {
      opacity: 1;
      transform: translateX(0) rotate(0); // Set translateX to 0 to bring it back to its original position and rotate
    }
  }
}

.section__image {
  border: solid black 0.3rem;
  width: 30rem;
  height: 30rem;
  object-fit: cover;
  border-radius: 50%; /* Use 50% for a perfect circle */
}

@media (max-width: 62.5rem) {
  .introduction__section {
    gap: 0rem;

    &__title {
      text-align: start;
      position: relative;
      top: 12rem;
      width: 100%;
      font-size: 3.5rem;
    }

    &__figure {
      width: 25rem;
      height: 25rem;
      border-radius: 50%;

      &.fade-in {
        opacity: 1;
        transform: translateX(0) rotate(0); // Set translateX to 0 to bring it back to its original position and rotate
      }

      img {
        border: solid black 0.3rem;
        width: 25rem;
        height: 25rem;
        object-fit: cover;
        border-radius: 50%; /* Use 50% for a perfect circle */
      }
    }
  }
}
