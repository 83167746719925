/* Reset styles */
@use "../abstracts/reset";
@use "../abstracts/darkmode";

/* Common styles for dark mode */
body {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10rem;
  width: 100%;
  padding: 2rem;
  transition: background-color 0.3s ease;

  &__buttons {
    cursor: pointer;
    font-size: 2.5rem;
    position: relative;
    text-decoration: none;
    color: #000;
    transition: color 0.3s ease;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: #000;
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out;
    }

    &:hover::after {
      visibility: visible;
      transform: scaleX(1);
    }
  }

  &__darkmode-toggle {
    display: flex;
    font-size: 4rem;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: #333;
    transition: color 0.3s ease;

    &.dark-mode {
      color: #fff;
    }

    &:hover {
      color: #555;
    }
  }

  /* Hamburger menu styles */
  &__hamburger-menu {
    color: white;
    cursor: pointer;
    display: none;
  }

  /* Navigation title styles */
  &__nav-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 6rem;
    width: 70%;
    font-family: sans-serif;
  }

  @media (max-width: 768px) {
    &__hamburger-menu {
      display: block; /* Display on smaller screens */
      position: relative;
      bottom: 2rem;
    }

    &__menu-toggle {
      font-size: 3rem;
      cursor: pointer;
      background-color: transparent;
      border: none;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      transition: transform 0.3s ease;

      &.open {
        transform: rotate(90deg);
      }

      span {
        width: 30px;
        height: 3px;
        background-color: #000;
        margin-bottom: 6px;
        transition: transform 0.3s ease, opacity 0.3s ease;
      }

      &.open span:first-child {
        transform: rotate(-45deg) translate(-5px, 6.5px);
      }

      &.open span:nth-child(2) {
        opacity: 0;
      }

      &.open span:nth-child(3) {
        transform: rotate(45deg) translate(-6px, -6.5px);
      }

      & span {
        width: 30px;
        height: 3px;
        background-color: #000;
        margin-bottom: 6px;
        transition: transform 0.3s ease, opacity 0.3s ease;
      }

      &.open span:last-child {
        transform: rotate(45deg) translate(-6px, -6.5px);
      }

      &:focus-visible {
        clip-path: none;
        -webkit-mask: none;
        border: none;
        outline: 2px solid #000;
        outline-offset: 5px;
      }
    }
  }

  /* Navigation styles */
  &__nav {
    display: flex;
    align-items: start;
    justify-content: flex-end;
    gap: 1.5rem;
    transition: background-color 0.3s ease, color 0.3s ease;

    &--dark {
      background-color: #333;
      color: #fff;
    }
  }

  @media screen and (max-width: 62.5rem) {
    .header {
      flex-direction: column;
      align-items: flex-start;
      transition: background-color 0.3s ease;

      &__nav {
        margin-top: 2rem;
        flex-direction: column;
        display: none;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;

        &__buttons {
          margin-top: 1rem;
        }
      }

      &__nav--open {
        display: flex;
        position: absolute;
        top: 5%;
        left: 2%;
        width: 100%;
      }

      /* Dark mode toggle styles for mobile */
      &__darkmode-toggle {
        position: absolute;
        top: 0.8rem;
        right: 2rem;
        transition: color 0.3s ease;
      }
    }
  }
}
