* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    font-size: 62.5%; /*1 rem wordt gelijk aan 10 px */
  }
  body {
    font-family: 'Dancing Script', cursive;
    font-family: 'Lato', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    background-color: whitesmoke;
    scroll-behavior: smooth;
  }


  button {
    background-color: transparent;
    border-width: 0;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 0;
  }