.project__detail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  flex-direction: column;

  &__title-and-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    width: 67%;
    flex-direction: row-reverse;
  }

  &__title {
    font-size: 5rem;
    transition: font-size 0.3s ease-in-out; /* Add transition property */
  }

  &__back__button {
    font-size: 6rem;
    cursor: pointer;
    transition: font-size 0.3s ease-in-out; /* Add transition property */
  }

  &__skills__and__duration {
    display: flex;
    flex-direction: row;
    gap: 4rem;
    width: 68%;
  }

  &__buttons__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: auto;
    gap: 5rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease;
    gap: 6rem;
    font-size: 3rem;
  }

  &__live-site {
    cursor: pointer;
    background: black;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    transition: background 0.3s ease;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }

  &__github {
    cursor: pointer;
    background: black;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    transition: background 0.3s ease;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }

  &__live-site:hover,
  &__github:hover {
    &:hover {
      background: darken(rgba(0, 0, 0, 0.815), 10%);
    }
  }

  .project__detail__skills {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 5rem;

    i {
      margin-right: 1rem;
    }
  }

  .project__detail__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130rem;
    height: 70rem;
    border-radius: 1rem;
    background-size: cover;
  }

  .slideshow-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 128rem;
    height: 70%;
    border-radius: 1rem;
  }

  .project__detail__image__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1.1rem;
    object-fit: contain;
  }

  .project__detail__image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: auto;
    border-radius: 1rem;
    width: 68.9%;
  }

  .project__detail__image img {
    border-radius: 1rem;
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  .slideshow-stripes {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 5px;
  }

  .stripe {
    width: 7rem;
    height: 0.7rem;
    background-color: #ccc;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 0.3rem;
  }

  .stripe.filled {
    background-color: #333;
  }

  .pause-button {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 2.2rem;
    background: none;
    border: none;
    color: #333;

    &:hover {
      color: #555;
    }
  }

  .slideshow-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    z-index: 1;
  }

  .slideshow-navigation button {
    background: transparent;
    border: none;
    color: rgb(0, 0, 0);
    font-size: 6rem;
    cursor: pointer;

    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .slideshow-navigation button:hover {
    background-color: rgba(255, 255, 255, 0.3);
    transform: scale(1.1);
  }

  .project__detail__duration {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    font-size: 4rem;
    flex-direction: row;

    i {
      margin-right: 5px;
    }
  }

  .project__detail__content__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    height: 30%;
  }

  .project__detail__description {
    justify-content: flex-start;
    width: 50%;
    height: 70%;
    font-size: 2.5rem;
    overflow: hidden;
    transition: font-size 0.3s ease-in-out, height 0.3s ease-in-out; /* Add transition properties */
  }

  .project__details__accordion__wrapper {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
  }

  .project__detail__accordion button {
    display: flex;
    align-items: flex-end;
    border-bottom: 0.2rem rgb(0, 0, 0) solid;
    width: 55rem;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out; /* Add transition property */

    span {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 90%;
    }

    &:hover {
      color: #333; /* Change to the desired hover text color */
      cursor: pointer;
    }
  }

  .project__detail__accordion {
    font-size: 2.5rem;
    gap: 3rem;

    &.open {
      .accordion__content {
        max-height: 500px;
        transition: max-height 0.3s ease-in-out;
      }
    }
  }

  .project__detail__accordion {
    .accordion__content {
      ul {
        list-style-type: none;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }

        i.fa-solid {
          margin-right: 10px;
        }
      }
    }
  }

  .accordion__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .dark-mode-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-top: 2rem;
    padding-right: 2rem;
    font-size: 4.5rem;
    cursor: pointer;
    color: #000000;
    border: none;
    transition: 0.3s;
  }

  .footer {
    height: 100%;
  }
}

@media screen and (max-width: 62.5rem) {
  .project__detail {
    height: 100%;
    overflow: hidden;

    &__title-and-button {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      text-align: center;
    }

    &__title {
      font-size: 3.5rem;
      text-align: end;
      padding-right: 1rem;
      flex-grow: 1; /* Allow title to take remaining space */
    }

    &__back__button {
      position: relative;
      left: 1.5rem;
      font-size: 4rem;
      order: -1; /* Move back button to the beginning */
    }
    &__skills__and__duration {
      width: 100%;
      flex-direction: column-reverse;
      gap: 0.2rem;
    }

    .project__detail__skills {
      justify-content: center;
      align-items: center;
      font-size: 4rem;
      i {
        margin-right: 0.5rem;
      }
    }

    .project__detail__duration {
      align-items: center;
      justify-content: center;
      font-size: 3rem;
      i {
        margin-right: 5px;
      }
    }

    .project__detail__image__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 1.1rem;
      object-fit: contain;
    }

    .project__detail__image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%; /* Adjust width */
      border-radius: 5px;
    }

    .project__detail__content__wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
    }

    .project__detail__description {
      justify-content: center;
      width: 100%;
      height: auto;
      font-size: 2rem;
      overflow: hidden;
      padding: 1rem;
    }

    .project__details__accordion__wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 2rem; /* Adjust padding */
    }

    .project__detail__accordion button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 0.2rem rgb(0, 0, 0) solid;
      width: 35rem; /* Adjust width */
    }

    .project__detail__accordion {
      font-size: 2rem;
    }

    .accordion__content.open {
      display: block;
    }
  }

  .project__detail .dark-mode-button {
    display: flex;
    justify-content: center; /* Adjust alignment */
    align-items: center;
    width: 100%;
    height: auto;
    font-size: 4rem;
    cursor: pointer;
    padding: 1rem; /* Adjust padding */
  }
}

@media screen and (max-width: 1200px) {
  .project__detail__image__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100rem;
    padding: 1.1rem;
    object-fit: contain;
  }

  .project__detail__image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100rem;
    width: 100rem; /* Adjust width */
    border-radius: 5px;
  }
}
