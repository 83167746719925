@use "../abstracts/reset";
@use "../abstracts/darkmode";

.footer {
  display: flex;
  justify-content: center;
  height: auto;
  width: 100%;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  
  .copyright {
    margin-top: 40px;
    font-size: 2rem;
    color: #000000; // Adjust the color as needed
    transition: 1s ease-in;

     &:hover {
      text-decoration: black underline;
    }
  }


  