@use "../abstracts/reset";
@use "../abstracts/darkmode";

.projects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;

  &__title {
    font-size: 3rem;
    text-align: center;
  }

  &__text {
    font-size: clamp(
      1.5rem,
      2.5rem,
      3rem
    ); /* Adjust the min, preferred, and max values as needed */
    text-align: left;
    margin-bottom: 1rem; /* Added margin for spacing */
    word-spacing: normal;
    line-height: 1.5;
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    gap: 3rem;
  }

  &__item {
    width: calc(50% - 50px); // Two items per row
    margin: 10px;
    display: flex;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.02);
    }
  }

  &__image {
    width: 40%; // Larger size for the image
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__language {
    display: flex;
    gap: 0.5rem;

    i {
      opacity: 1;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.7;
        animation: bounce 1s infinite alternate cubic-bezier(0.5, 0.05, 1, 0.5);
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: row; // Updated to a column layout
    align-items: center; // Center items vertically
    gap: 1rem;
    width: 100%;
  }

  &__link {
    padding: 8px 16px;
    background-color: #000000;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    text-align: center;
    width: 40%;
    margin-top: 2rem;

    &:hover {
      background-color: #414649;
    }
  }

  &__github {
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    top: 0.9rem;
    align-items: center;
    justify-content: flex-end;

    gap: 0.5rem;
    font-size: 4rem;

    a {
      color: #000000;
      text-decoration: none;

      i {
        opacity: 1;
        transition: opacity 0.3s ease;

        &:hover {
          opacity: 0.7;
          animation: bounce 1s infinite alternate
            cubic-bezier(0.5, 0.05, 1, 0.5);
        }
      }
    }
  }

  &__empty {
    color: #e74c3c;
    font-style: italic;
  }

  @media (max-width: 62.5rem) {
    &__item {
      width: 100%;
      flex-direction: column;
    }

    &__image {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }

    &__title,
    &__text {
      text-align: center;
    }

    &__link {
      padding: 8px 16px;
      background-color: #000000;
      color: #fff;
      text-decoration: none;
      border-radius: 4px;
      transition: background-color 0.3s ease;
      text-align: center;
      width: 90%;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 3rem;
    width: 100%;
    margin: 1px 0px;
    gap: 1rem;
  }

  .projects__heading {
    display: flex;
    justify-content: center;
    font-size: 4rem;
  }
}
