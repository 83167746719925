.work {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: auto;
    width: 100%;
    gap: 2rem; /* Adjusted to a smaller gap */
  
    .work__heading {
      font-size: 4rem;
      margin-top: 8rem;
    }
  
    .work-container {
      width: 80%;
      height: auto;
      margin: 0 auto;
      position: relative;
      margin-top: 2rem;
  
      .slick-slider {
        position: relative;
  
        .slick-slide {
          position: relative;
          text-align: center;
          overflow: hidden;
          width: 100%; /* Each slide takes full width */
          flex: 0 0 auto; /* Ensure slides do not shrink or grow */
  
          img {
            max-width: 100%;
            height: 380px;
            border-radius: 8px;
            object-fit: cover;
            filter: brightness(70%);
          }
  
          .slick-dots {
            position: absolute;
            bottom: 10px;
            left: 0;
            right: 0;
            text-align: center;
            list-style: none;
            padding: 0;
            margin: 0;
            display: none;
  
            li {
              display: inline-block;
              margin: 0 5px;
  
              button {
                font-size: 0;
                width: 12px;
                height: 12px;
                border: none;
                background-color: #ccc;
                border-radius: 50%;
                cursor: pointer;
                transition: background-color 0.3s ease;
  
                &.slick-active {
                  background-color: #000;
                  display: none;
                }
  
                &:hover {
                  background-color: #555;
                }
              }
            }
          }
        }
  
        .slick-list {
          display: flex;
          overflow: hidden;
          width: 100%; /* Ensure the list takes full width */
        }
  
        .slick-track {
          display: flex;
          width: 100%; /* Ensure tracks take full width */
          transition: transform 0.3s ease;
        }
  
        .slick-arrow {
          display: block; /* Show arrows by default */
  
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          color: #000000;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          cursor: pointer;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 0.3s ease;
  
          &.slick-prev {
            left: 0;
          }
  
          &.slick-next {
            right: 0;
          }
  
          svg {
            font-size: 3.4rem;
          }
        }
  
        // Responsive adjustments
        @media (max-width: 768px) {
          .slick-slide {
            .slick-dots {
              display: block; /* Show dots only on smaller screens */
            }
  
            .slick-arrow {
              display: none; /* Hide arrows on smaller screens */
            }
          }
        }
      }
    }
  }
  