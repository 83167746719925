@use "../abstracts/reset";
@use "../abstracts/darkmode";

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.skills-heading {
  text-align: center;
  font-size: 5rem;
  margin-bottom: 40px;
}

.skill-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  width: calc(50% - 20px);
  max-width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px) scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  &__icon {
    font-size: 3.5rem;
    margin-top: 20px;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  .hovered &__icon {
    transform: rotate(360deg);
    color: #555;
  }

  &__content {
    flex-grow: 1;
    padding: 20px;
  }

  &__title {
    font-size: 2.6rem;
  }

  &__text {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }

  &__experience {
    font-size: 1.6rem;
    font-style: italic;
    color: #666;
  }
}

@media (max-width: 62.5rem) {
  .skills-container {
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
  }

  .skill-card {
    width: 100%;
  }
}
